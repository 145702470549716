
html {
  overflow:scroll !important;
  overflow-x: hidden !important;
}

@font-face {
  font-family: 'Melbourne';
  src: url('../public/Melbourne/Melbourne_reg.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


body {
  width:100%;
  overflow-x: hidden !important;
  font-size: 0.9rem;
  font-family: 'Melbourne', sans-serif;
  padding-right: 0% !important;
}

p ,h1 ,h2 , h3, h4, h5, h6{
  font-family: 'Melbourne', sans-serif;
}





.FirstAppBar{
  background-color: #FEFEFE;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 14vh;
  
}


.HeroImg{
background-color:#FEFEFE;

width: 100%;
height:auto;
}

.HeroImg img{
  object-fit: fill;
}

.AboutDiv{
  display: flex;
  width: 100%;
  padding: 5%;
}
.AboutPara{
  font-size: 1.2rem;
  padding: 2%;
  position: relative;
  overflow: hidden;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 2px );
  -webkit-backdrop-filter: blur( 2px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.AboutLogo{
  display: flex;
  margin: auto;
  width: 50%;
  height: auto;
}


.Footer{
  display:inline-flex;
  background-color:#010203;
  padding: 5%;
  bottom: 0;
  width: 100%;
  height:auto;
  color: #909090;
  
}

.Footer h3{
  color: #FEFEFE;
}

ul {
  list-style-type: none;
}

.Links{
width: 25%;
}
.Locations{
  width: 25%;
}
.Connect{
  width: 25%;
}

.item{
  border: #010203 1px ridge;
}



.css-hyum1k-MuiToolbar-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
} 


@media only screen and (min-width: 600px) {
  .Footer{
    display: block;
  }
  .Links{
    width: 100%;
    }
    .Locations{
      width: 100%;
    }
    .Connect{
      width: 100%;
    }
  .AboutDiv{
    display: block;
  }
  .customModal{
    top:20%;
      }
      .press h3{
        font-size: 0.9rem;
     }

     .contact{
      display: block !important;
    }
  
}
@media only screen and (min-width: 900px) {
  .Footer{
    display: inline-flex;
  }
  .AboutDiv{
    display: flex;
  }
  .press h3{
     font-size: 1.2rem;
  }
  .contact{
    display: flex !important;
  }
  
}

/* styles for screens 900px and wider */

@media only screen and (max-width: 600px) {
  .Footer {
    display: block;
    
  }
  .Links{
    width: 100%;
    }
    .Locations{
      width: 100%;
    }
    .Connect{
      width: 100%;
    }
  .AboutDiv{
    display: block;
  }
  .press h3{
    font-size: 0.7rem;
 }

 .contact{
  display: block !important;
}

}



.KnowMore{
  display: flex;
  justify-content: center;
  align-content: center;
}

.AboutUs{
  padding-top:10vh ;
}
.About{
  margin: 5%;
}

.TrunkShow{
  height:100vh;
  padding: 5%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
}



.contact{
  display: flex;
  background-image: url("./assets/TrunkShow/trucker\ jacket\ \(1\).jpg");
  background-size: 100% auto;
  background-repeat: repeat;
  object-fit: contain;
  width: 100%;
  height: auto;
  padding: 5%;
}

.contactdiv{
width: 100%;
height:100%;
color: aliceblue;
margin: 5%;
}



.SpringSummer{
width: 15%;
float: left;
padding-top: 1%;
padding-left: 5%;
}

.AutumnWinter{
  width: 25%;
  float: left;
  padding-left: 5%;
}

.Archive{
  width: 25%;
  float: left;
  padding-left: 5%;
}

.celebrities{
  display:flex;
  width: 90%;
 justify-content: center;
  align-items: center;
  
}
.CelebrityDiv{
  display: flex;
  padding-top:16px ;
  background: rgb(39,40,41);
  background: linear-gradient(90deg, rgba(39,40,41,1) 0%, rgba(19,20,21,1) 41%, rgba(35,36,37,1) 55%, rgba(58,59,60,1) 63%, rgba(76,77,78,1) 69%, rgba(93,94,95,1) 79%, rgba(132,132,133,1) 90%, rgba(169,169,170,1) 95%, rgba(206,206,206,1) 98%, rgba(255,255,255,1) 100%);
}

.Shop{
  height: 100vh;
  width: 100%;
}

.Shop img{
  object-fit:contain;
  height: 100%;
  width: 100%;
}

#emailfooter{
  position: absolute;
  left:30px;
  color: #909090;
}





a { color: inherit; } 

.collectiondiv{
  display: inline-flex;
}


.customModal {
  
  width: 80%;
  height: auto;
}

.press{
  display: flex;
  justify-content: center;
  
}

.Mills{
  width: 100%;
  height: 80vh;
}



.imageButton{
 

}

.imageDescription{
display: flex;
position: absolute;
bottom: 0;
width: 100%;
height: auto;
padding: 5%;
background: rgba( 255, 255, 255, 0.25 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border: 1px solid rgba( 255, 255, 255, 0.18 );
}



.markercluster-map{
  height: 90vh;
}